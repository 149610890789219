import * as React from 'react'
// import Navbar from '../components/navbar'
import Seo from '../components/seo'
import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
// import PostLink from "../components/post-link"
// import promoSlika from '../images/kruzic-promo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// const imgStyle = `mx-auto rounded-lg shadow-2xl`

// <Link to='/'>Idi na naslovnu stranicu...</Link>.

library.add(fas)

// const imgStyle = `mx-auto rounded-lg shadow-2xl`

const IndexPage = ({
  data: {
    allMdx: { edges },
  }, // this prop will be injected by the GraphQL query below.
}) => {
  // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
  // .map(edge => <p key={edge.node.id}>{edge.node} <p/>)
  return (
    <>
      <Seo title='Naslovnica' />
      <Layout>
        <p className='h-4'></p>
        <StaticImage
          src='../images/kruzic-hvala-2.jpg'
          alt='Mario i Karliško'
          placeholder='blurred'
          quality='100'
          as='div'
          className='my-8 wide'
        />
        {/* <Video
          videoSrcURL='https://youtube.com/embed/8s-GoMzLWZI'
          videoTitle='Izađite na izbore i zaokružite 2 za Maria Kružića'
        /> */}
        <section className='my-8'>
          <p className='text-xl mb-10'>Zahvala biračima na podr&scaron;ci</p>
          <p>
            Veliko HVALA biračima na podr&scaron;ci! Opravdat ćemo povjerenje i
            već od sutra počinje borba za novu i bolju Crikvenicu. Naravno,
            legitimno, kroz rad Gradskog vijeća.&nbsp;
          </p>
          <p>
            I dalje ćemo u svakom trenutku svojih aktivnosti zastupati interese
            svih onih koji su prepoznali snagu na&scaron;e liste odličnih ljudi
            za promjene koje su neminovne.&nbsp;
          </p>
          <p>
            Kad, ako ne sad? Jer, brzo će idući izbori, možda i prije nego
            očekujemo.&nbsp;
          </p>
          <p>
            Hvala i timu sjajnih ljudi koji su cijelu kampanju stajali iza
            Karli&scaron;ka i mene, na&scaron;im stranačkim drugovima i
            simpatizerima te obiteljima. Promjene su već tu i nemoguće ih je
            zaustaviti!
          </p>
          <p>Va&scaron;i Mario i Karli&scaron;ko</p>
        </section>
        <hr />
        <section className='objave my-8'>
          <div className='grid grid-cols-objavefit gap-8 my-8'>
            {edges.map(({ node }) => {
              const { title, slug, date, promoSlika } = node.frontmatter
              return (
                <article key={node.id} className='max-w-3xl'>
                  {promoSlika ? (
                    <a href={slug}>
                      <GatsbyImage
                        image={promoSlika.childImageSharp.gatsbyImageData}
                      />
                    </a>
                  ) : (
                    <StaticImage
                      src='../images/kruzic-promo-4.png'
                      alt='Mario Kružić'
                      placeholder='blurred'
                      quality='100'
                      as='div'
                      className='mb-8 wide'
                    />
                  )}
                  <h3 key={slug}>
                    <a href={slug}>{title}</a>
                  </h3>
                  <p className='text-sm text-siva'>{date}</p>
                  <p className=''>{node.excerpt}</p>
                  <p className='text-right mt-2'>
                    <a href={slug} className='no-underline py-2 text-siva '>
                      Pročitaj ostatak
                      <FontAwesomeIcon icon='plus-circle' className='ml-2' />
                    </a>
                  </p>
                </article>
              )
            })}
          </div>
        </section>
        {/* <StaticImage
          src='../images/kruzic-header-2krug.png'
          alt='Zaokruži 2 - Mario Kružić, snaga za promjenu'
          placeholder='blurred'
          quality='100'
          as='div'
          className='my-8 wide'
        /> */}
        {/* <StaticImage
          src='../images/kruzic-header-2krug.png'
          alt='Zaokruži 2 - Mario Kružić, snaga za promjenu'
          placeholder='blurred'
          quality='100'
          as='div'
          className='mb-4 wide'
        /> */}
        {/* <section className='video-klipovi'>
          <div className='grid grid-cols-objavefit gap-8 my-8'>
            <Video
              videoSrcURL='https://youtube.com/embed/3pFntbZGvBQ'
              videoTitle='Kad poštuješ more, more uvijek da'
            />
            <div></div>
            <div></div>
          </div>
        </section> */}
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
    allMdx(
      limit: 3
      filter: { fileAbsolutePath: { regex: "/objave/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "dddd, DD. MMMM, YYYY.", locale: "hr")
            title
            slug
            promoSlika {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
